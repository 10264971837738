import Loadable from "react-loadable";
import Loading from "./Loading";

// const AsyncAboutUs = Loadable({
//   loader: () => import('../containers/Authentication/AboutUs'),
//   loading: Loading
// });
//
// const AsyncContactUs = Loadable({
//   loader: () => import('../containers/Authentication/ContactUs'),
//   loading: Loading
// });
//

const AsyncHome = Loadable({
  loader: () => import("../containers/Home"),
  loading: Loading
});

const AsyncJobDetails = Loadable({
  loader: () => import("../containers/Jobs/JobDetail"),
  loading: Loading
});

const AsyncArticles = Loadable({
  loader: () => import("../containers/Articles"),
  loading: Loading
});
const AsyncArticleDetails = Loadable({
  loader: () => import("../containers/Articles/ArticleDetails"),
  loading: Loading
});

let PublicRoutes = [
  {
    title: "Home",
    path: "/",
    component: AsyncHome,
    exact: true
  },
  {
    title: "About",
    path: "/#about",
    component: AsyncHome,
    exact: true
  },
  {
    title: "Contact",
    path: "/#contact",
    component: AsyncHome,
    exact: true
  },
  {
    title: "JobDetails",
    path: "/jobs/details",
    component: AsyncJobDetails,
    exact: true
  },
  {
    title: "Articles",
    path: "/articles",
    component: AsyncArticles,
    exact: true
  },
  {
    title: "ArticleDetails",
    path: "/articles/details",
    component: AsyncArticleDetails,
    exact: true
  }
];
export default PublicRoutes;
