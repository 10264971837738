import React from "react";
import styled from "styled-components";
import { Col, Icon, Row } from "antd";
import { Colors, Metrics, Images } from "../../themes";
import { convertPixelsToRem } from "../../utils/helpers";

const { icons, padding } = Metrics;
const { logo } = Images;
const { green, textGray, white } = Colors;

const Wrapper = styled.div`
  bottom: 0;
  position: ${props => props.position || "absolute"};
  width: 100%;
  background: ${textGray};
  padding: ${convertPixelsToRem(2)} 2%;
  height: 20vh;
  @media (max-width: 700px) {
    height: 40vh;
  }
  @media (max-width: 400px) {
    height: 30vh;
  }
`;
const Links = styled(Row)``;
const LinkWrapper = styled(Col)`
  white-space: nowrap;
  display: inline-flex !important;
  align-items: "center" !important;
  padding-top: 2%;
`;

const LinkList = styled.p`
  // width: 39px;
  // height: 16px;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.white};
`;

const LinkIcon = styled(Icon)`
  font-size: ${icons.medium};
  padding: 0 ${padding.baseHorizontal};
  color: ${green};
`;

const FooterContainer = props => {
  const currentYear = () => {
    const year = new Date();
    return year.getFullYear();
  };
  return (
    <Wrapper position={props.position}>
      <Links type="flex" justify="space-around" gutter={16}>
        <Col xs={0} sm={0} md={6} lg={6}>
          <LinkList>
            <img src={logo} alt="logo" />
          </LinkList>
        </Col>
        <LinkWrapper
          xs={24}
          sm={24}
          md={10}
          lg={7}
          style={{ justifyContent: "space-between" }}
        >
          <LinkList>Privacy Policy</LinkList>
          <LinkList>&nbsp; &nbsp; Terms & Conditions</LinkList>
        </LinkWrapper>
        <LinkWrapper
          xs={24}
          sm={24}
          md={7}
          lg={4}
          style={{ display: "inline-flex", justifyContent: "space-between" }}
        >
          <a
            href="https://www.facebook.com/Healthhunterafrica/?view_public_for=625649664569734"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon type="facebook" theme="filled" />
          </a>
          <a
            href="https://twitter.com/HealthHunterAf1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon type="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/health-hunter-africa/about/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon type="linkedin" />
          </a>
          <a
            href="https://www.instagram.com/healthhunterafrica/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon type="instagram" />
          </a>
        </LinkWrapper>
      </Links>
      <Links>
        <Col xs={24} sm={24} md={0} lg={0}>
          <br />
        </Col>
        <Col span={24} style={{ color: white }}>
          &#169;{currentYear()} HealthHunter. All rights reserved
        </Col>
      </Links>
    </Wrapper>
  );
};
export default FooterContainer;
